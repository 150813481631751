import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

type Props = {
  baseUrl: string;
  region: Partial<DataEstate.IEstate>;
};

const useStyles = makeStyles({
  itemStyle: {
    paddingRight: "25px",
    position: "relative",
    display: "block",
    color: "white",
    fontWeight: 800,
    fontSize: "1.25em",
    overflow: "hidden",
    marginBottom: "0px",
    backgroundSize: "cover",
    backgroundPosition: "centre",
    backgroundColor: "#e3e3e3",
    height: "320px",
  },
  itemContainer: {
    marginRight: "5px",
    marginLeft: "5px",
  },
  itemTitle: {
    position: "absolute",
    verticalAlign: "bottom",
    float: "left",
    display: "inline-block",
    bottom: "15px",
    marginLeft: "15px",
  },
});

export const RegionsCarouselItem = ({
  baseUrl,
  region,
}: Props): JSX.Element => {
  const searchUrl = `${baseUrl}/search?regions=${encodeURIComponent(
    region.name
  )}`;
  const classes = useStyles();
  const backgroundImage = region.hero_image
    ? { backgroundImage: `url(${region.hero_image.path})` }
    : {};

  return (
    <div className={classnames("item-container", classes.itemContainer)}>
      <a
        className={classnames("region", classes.itemStyle)}
        href={searchUrl}
        style={backgroundImage}
      >
        <div className="carousel-card">
          <span
            className={classnames("carousel-card-title", classes.itemTitle)}
          >
            {" "}
            {region.name}
          </span>
        </div>
      </a>
    </div>
  );
};

export default RegionsCarouselItem;
