import React, { Fragment } from "react";
import { render } from "react-dom";
import SearchProvider from "../context/SearchProvider";
import StateConfigContext from "../context/StateConfigContext";
import { SearchBar } from "../SearchBar";

type RenderSearchBar = (
  domId: string,
  baseUrl: string,
  stateCode: StateCode
) => void;

export const renderSearchBar: RenderSearchBar = (domId, baseUrl, stateCode) => {
  const appContainer = document.getElementById(domId);

  render(
    <StateConfigContext.Provider
      value={{
        baseUrl,
        stateCode,
      }}
    >
      <SearchProvider>
        <SearchBar />
      </SearchProvider>
    </StateConfigContext.Provider>,
    appContainer
  );
};

export default renderSearchBar;
