import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import superagent from "superagent";
import { useDebouncedCallback } from "use-debounce";
import {
  makeStyles,
  ClickAwayListener,
  Fade,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { getHighlightedText } from "../helpers/getHighlightedText";
import StateConfigContext from "../context/StateConfigContext";
import useSearchContext from "../hooks/useSearchContext";
import { setQueryParamAction } from "../context/SearchActions";

type Props = {
  placeholder?: string;
  minLength?: number;
};

type KeywordSearchResult = {
  id: string;
  name: string;
};

const useStyles = makeStyles({
  container: {},
  input: {
    border: "none",
    padding: "0 16px",
  },
  searchResult: {
    position: "absolute",
    background: "white",
    boxShadow: "1px 4px 6px 0px #00000030",
    padding: "16px",
    zIndex: 100,
    "& h3": {
      marginBottom: "8px",
    },
  },
  keywordSearchResult: {},
});

const defaultSearchQuery = {
  categories:
    "ACCOMM,ATTRACTION,INFO,HIRE,TRANSPORT,TOUR,RESTAURANT,GENSERVICE",
  fields: "id,name",
  qtab: true,
  size: 5,
};

export const KeywordSearch = ({
  placeholder = "Keywords",
  minLength = 4,
}: Props) => {
  const classes = useStyles();
  const { baseUrl, stateCode } = useContext(StateConfigContext);
  const { searchQuery, dispatch } = useSearchContext();
  const [keyword, setKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [keywordResult, setKeywordResult] = useState<KeywordSearchResult[]>([]);

  const fetchKeyword = async (
    searchKeyword: string,
    searchInState: StateCode
  ) => {
    console.log(`Searching: ${searchKeyword}`);
    //Searching
    const requestUri = `${baseUrl}/api/estates/data`;
    try {
      const response: superagent.Response = await superagent
        .get(requestUri)
        .query({
          ...defaultSearchQuery,
          state_code: searchInState,
          name: searchKeyword,
        });
      setKeywordResult(response.body as KeywordSearchResult[]);
    } catch (error) {
      console.log(error);
      setKeywordResult([]);
    }
  };

  const doSearch = (searchKeyword: string, searchInState: StateCode) => {
    fetchKeyword(searchKeyword, searchInState);
  };

  const doDebouncedSearch = useDebouncedCallback(doSearch, 500);

  const handleKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSelectListing = (resultId: string) => {
    console.log(`Selected: ${resultId}`);
    const newQuery = {
      ...(searchQuery || {}),
      id: resultId,
    };
    console.log(newQuery);
    if (resultId) {
      dispatch(setQueryParamAction(newQuery));
    }
  };

  useEffect(() => {
    if (keyword.length >= minLength) {
      doDebouncedSearch.callback(keyword, stateCode);
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [keyword]);

  useEffect(() => {
    console.log(keywordResult);
  }, [keywordResult]);

  const renderKeywordResult = keywordResult.map(
    (keywordResult: KeywordSearchResult) => (
      <MenuItem
        key={keywordResult.id}
        onClick={() => handleSelectListing(keywordResult.id)}
      >
        {getHighlightedText(keywordResult.name, keyword, 48)}
      </MenuItem>
    )
  );

  return (
    <div className={classnames("KeywordSearch", classes.container)}>
      <input
        type="text"
        placeholder={placeholder}
        value={keyword}
        onChange={handleKeyword}
        className={classnames("KeywordSearch__input", classes.input)}
      />
      <ClickAwayListener onClickAway={handleClickAway}>
        <Fade in={open}>
          <div className={classes.searchResult}>
            <div>
              <h3>Keyword</h3>
              <span>{keyword}</span>
            </div>
            {keywordResult && (
              <div className={classes.keywordSearchResult}>
                <h3>Quick Search</h3>
                <MenuList>{renderKeywordResult}</MenuList>
              </div>
            )}
          </div>
        </Fade>
      </ClickAwayListener>
    </div>
  );
};

export default KeywordSearch;
