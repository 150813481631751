import React, { useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { makeStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

type Props = {
  id?: string;
  panels: React.ReactNode[];
};

const useStyles = makeStyles({
  carouselNavContainer: {
    marginRight: "5px",
    textAlign: "right",
  },
});

const PanelsCarousel = ({
  id = "panelsContainer",
  panels,
}: Props): JSX.Element => {
  //Functions
  const aliceCarousel = useRef(null);
  const [showButtons, setShowButtons] = useState(true);
  const classes = useStyles();
  const handleResize = (ev) => {
    if (ev.itemsInSlide == panels.length) {
      setShowButtons(false);
    } else {
      setShowButtons(true);
    }
  };

  const responsiveOptions = {
    0: {
      items: 1,
    },
    760: {
      items: 3,
    },
    1024: {
      items: 5,
    },
  };

  const navButtons = showButtons ? (
    <div className={classes.carouselNavContainer}>
      <button
        className="carousel-btn btn-prev"
        onClick={() => aliceCarousel.current.slidePrev()}
      >
        <ChevronLeft />
      </button>
      <button
        className="carousel-btn btn-next"
        onClick={() => aliceCarousel.current.slideNext()}
      >
        <ChevronRight />
      </button>
    </div>
  ) : (
    ""
  );
  return (
    <div id={id} className="panels-carousel">
      <AliceCarousel
        mouseTrackingEnabled={false}
        infinite={false}
        items={panels}
        responsive={responsiveOptions}
        buttonsDisabled={true}
        ref={aliceCarousel}
        onInitialized={handleResize}
        onResized={handleResize}
      ></AliceCarousel>
      {navButtons}
    </div>
  );
};

export default PanelsCarousel;
