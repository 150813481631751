import { createContext } from "react";
import { getDefaultSearchContextState } from "./getDefaultSearchContextState";
import { SearchContextValue } from "./types";

export const SearchContext = createContext<SearchContextValue>({
  ...getDefaultSearchContextState(),
  dispatch: () => {},
});

export default SearchContext;
