import React, { useEffect, useReducer, useContext } from "react";
import { SearchContext } from "./SearchContext";
import SearchReducer from "./SearchReducer";
import getDefaultSearchContextState from "./getDefaultSearchContextState";
import StateConfigContext from "./StateConfigContext";

type Props = {
  searchQuery?: null | {
    [key: string]: string;
  };
  children?: React.ReactNode;
};

const { Provider } = SearchContext;

export const SearchProvider = ({
  searchQuery,
  children,
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer(SearchReducer, {
    ...getDefaultSearchContextState(),
    searchQuery,
  });
  const { baseUrl } = useContext(StateConfigContext);

  const pushQueryParams = (queryParams: { [key: string]: string }) => {
    const searchQueryString = Object.entries(queryParams)
      .map((keyVal) => keyVal.join("="))
      .join("&");
    // console.log("Current State: ", window.history.state);
    // window.history.pushState(
    //   {
    //     id: "searchPage",
    //   },
    //   "",
    //   `/search?${searchQueryString}`
    // );
    document.location.search = searchQueryString;
  };

  useEffect(() => {
    console.log("Query Updated", state.searchQuery);
    if (state.searchQuery) {
      pushQueryParams(state.searchQuery || {});
    }
  }, [state.searchQuery]);

  return (
    <Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </Provider>
  );
};

export default SearchProvider;
