import React, { Fragment } from "react";

export const getHighlightedText = (
  text: string,
  search: string,
  limit?: number
) => {
  const textToSearch = limit ? text.substr(0, limit) : text;
  const parts = textToSearch.split(new RegExp(`(${search})`, "gi"));
  return (
    <span>
      {parts.map((part, idx) =>
        part.toLowerCase() === search.toLowerCase() ? (
          <b key={idx} className="matched-word">
            {part}
          </b>
        ) : (
          <Fragment key={idx}>{part}</Fragment>
        )
      )}
      {limit && text.length > limit ? "..." : ""}
    </span>
  );
};

export default getHighlightedText;
