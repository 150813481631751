export type SearchReducerAction = SetQueryParamActionResult;

type SetQueryParamActionResult = {
  type: "SET_QUERY_PARAM";
  queryParam: {
    [key: string]: string;
  };
};

export const setQueryParamAction = (queryParam: {
  [key: string]: string;
}): SetQueryParamActionResult => ({
  type: "SET_QUERY_PARAM",
  queryParam,
});
