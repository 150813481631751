import React, { useState, useEffect } from "react";
import RegionsCarouselItem from "./RegionsCarouselItem";
import PanelsCarousel from "../../Common/components/PanelsCarousel";
import fetch from "cross-fetch";

type Props = {
  baseUrl: string;
  stateCode: StateCode;
};

const RegionsCarousel = ({ baseUrl, stateCode }: Props): JSX.Element => {
  const [regions, setRegions] = useState([]);
  const shouldUpdate = "a";
  const regionUrl = `${baseUrl}/api/locations/country/AU/states/${stateCode}/regions?fields=id,name,state_code,hero_image,parent,related_estate`;

  useEffect(() => {
    fetch(regionUrl)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const regionData = res.filter((region) => !("parent" in region));
        setRegions(regionData);
      });
  }, [shouldUpdate]);

  const panels = regions
    ? regions.map((item) => {
        return <RegionsCarouselItem baseUrl={baseUrl} region={item} />;
      })
    : [];

  return (
    <div className="regions-carousel-container">
      <PanelsCarousel panels={panels} />
    </div>
  );
};

export default RegionsCarousel;
