import { SearchContextState } from "./types";
import { SearchReducerAction } from "./SearchActions";

export const SearchReducer = (
  state: SearchContextState,
  action: SearchReducerAction
): SearchContextState => {
  switch (action.type) {
    case "SET_QUERY_PARAM":
      return {
        ...state,
        searchQuery: action.queryParam,
      };
    default:
      return state;
  }
};

export default SearchReducer;
