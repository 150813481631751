import React from "react";
import { makeStyles } from "@material-ui/core";
import { KeywordSearch } from "./KeywordSearch";

const useStyles = makeStyles({
  searchBar: {
    display: "flex",
  },
});

export const SearchBar = () => {
  const classes = useStyles();
  return (
    <div className={classes.searchBar}>
      <KeywordSearch />
    </div>
  );
};

export default SearchBar;
