import React, { Fragment } from "react";
import { render } from "react-dom";
import { RegionsCarousel } from "../RegionsCarousel";

type RenderCarousel = (
  domId: string,
  baseUrl: string,
  stateCode: StateCode
) => void;

export const renderCarousel: RenderCarousel = (
  domId: string,
  baseUrl: string,
  stateCode = "WA"
) => {
  const appContainer = document.getElementById(domId);

  render(
    <Fragment>
      <RegionsCarousel baseUrl={baseUrl} stateCode={stateCode} />
    </Fragment>,
    appContainer
  );
};

export default renderCarousel;
